export const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
export const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview";
export const isStaging =
  isPreview && process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "staging";
export const isTesting = process.env.NODE_ENV === "test";
export const isLocal = !process.env.NEXT_PUBLIC_VERCEL_ENV;

export const getEnvironment = () => {
  if (isProduction) return "production";
  if (isStaging) return "staging";
  if (isPreview) return "preview";
  if (isLocal) return "local";
  if (isTesting) return "testing";
  return undefined;
};

// Backend service name for API tracing
export const SERVICE_NAME = "aicc";
// Frontend service name for RUM
export const SERVICE_APP_NAME = "ai-command-center";

export const ALLOWED_TRACING_ORIGINS: (RegExp | string)[] = [
  /https:\/\/(.*\.)?zapier(-staging|-deployment)?\.com/,
];
