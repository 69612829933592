globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"b47304f5d1313f1c73f1d46c00595bc063743c4d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { FullStory } from "@fullstory/browser";
import { fullStoryIntegration } from "@sentry/fullstory";
import * as Sentry from "@sentry/nextjs";
import { ErrorInfo as AblyErrorInfo } from "ably";

import { isProduction, isStaging } from "./src/lib/environment";

Sentry.init({
  dsn: "https://742d563a5c69e9f5ddb91198378a82cf@o23399.ingest.sentry.io/4506346502619136",

  enabled: isProduction || isStaging,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Enable Session Replay for 100% of error sessions
  replaysOnErrorSampleRate: 1.0,
  // Disable replays for non-error sessions
  replaysSessionSampleRate: 0,

  ignoreErrors: [
    // Ignore obnoxious ResizeObserver errors that are useless
    "ResizeObserver loop completed with undelivered notifications",
    "ResizeObserver loop limit exceeded",
  ],

  beforeSend(event, hint) {
    // this error needs to be filtered out since it causes Sentry to hit its rate limit
    if (
      event.exception?.values?.find((value) => {
        return value.stacktrace?.frames?.find((frame) => {
          return frame.filename === "app:///userscript.html";
        });
      })
    ) {
      return null;
    }

    const error = hint.originalException;
    if (error instanceof AblyErrorInfo) {
      // Ignore Ably "Connection closed" error.
      // This gets thrown occasionally when the browser closes the Ably connection
      // while trying to send a presence update.
      // See also https://github.com/ably/ably-js/issues/657
      if (error.code === 80017) {
        return null;
      }
    }

    // combine AI actions JSON errors into one
    if (event.message === "AI Actions response was not JSON") {
      event.fingerprint = ["ai-actions-not-json", "client"];
    }

    return event;
  },

  beforeBreadcrumb(breadcrumb, hint) {
    if (
      breadcrumb.category === "http" &&
      (breadcrumb.data?.url.startsWith(
        "https://http-intake.logs.datadoghq.com",
      ) ||
        breadcrumb.data?.url.startsWith("https://api.mixpanel.com") ||
        breadcrumb.data?.url.startsWith("https://rs.fullstory.com"))
    ) {
      return null;
    }
    return breadcrumb;
  },

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    // We don't currently pay for session replay in Sentry
    // Sentry.replayIntegration({
    //   // Additional Replay configuration goes in here, for example:
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
    fullStoryIntegration("zapier-1", { client: FullStory }),
  ],
});
